import React, { useState, useCallback, useContext, useEffect, useMemo } from "react";
import { graphql } from "gatsby";
import { useQueryParam, StringParam } from "use-query-params";

import { login, verifyPasswordResetCode, resetPassword } from "../api/auth";
import { useNavigateLocalized } from "../hooks/navigate-localized";
import { useTranslations } from "../hooks/translations";
import { REQUIRED, MIN_LENGTH } from "../utils/validators";
import InfoPopupContext from "../info-popup-context";
import SEO from "../components/seo";
import Form from "../components/form";
import FormInput from "../components/form-input";
import Button from "../components/button";
import StatusModal from "../components/status-modal";
import SpinnerOverlay from "../components/spinner-overlay";

export const query = graphql`
  query AccountActionQuery($locale: String!) {
    translations(locale: $locale) {
      pages {
        accountAction
      }
      form
      ...LayoutTranslations
    }
  }
`;

const FIELDS = {
  password: {
    value: "",
    required: true,
    validators: [
      {
        validate: REQUIRED,
        message: "passwordRequired"
      },
      {
        validate: MIN_LENGTH(8),
        message: "passwordTooShort"
      }
    ]
  }
};

const AccountActionPage = () => {
  const t = useTranslations("pages.accountAction");

  const [mode] = useQueryParam("mode", StringParam);

  const [code] = useQueryParam("oobCode", StringParam);

  const pageValid = useMemo(() => mode === "resetPassword" && code, []);

  const [email, setEmail] = useState(null);

  const [error, setError] = useState(null);

  const navigateToHome = useNavigateLocalized("/");

  const errorCloseCallback = useCallback(() => {
    if (error === t.invalidCode) {
      navigateToHome();
    } else {
      setError(null);
    }
  }, [error, setError, navigateToHome]);

  const verifyCode = useCallback(() => {
    verifyPasswordResetCode(code).then(setEmail, () => {
      setError(t.invalidCode);
    });
  }, []);

  const navigateToNotFound = useNavigateLocalized("/not-found");

  useEffect(() => {
    if (!pageValid) {
      navigateToNotFound();
    } else {
      verifyCode();
    }
  }, []);

  const [submitting, setSubmitting] = useState(false);

  const infoPopup = useContext(InfoPopupContext);

  const submitForm = useCallback(
    ({ password }) => {
      if (submitting) {
        return;
      }

      setSubmitting(true);

      resetPassword({
        code,
        password
      })
        .then(
          () => {
            infoPopup.display({
              message: t.success
            });
            // I need to fetch the current user's data. This is a hacky way to do it,
            // but there does not seem to be a better option
            return login({ email, password });
          },
          err => {
            setError(
              err.code === "auth/user-disabled" ? t.accountDisabled : t.passwordChangeFailure
            );
          }
        )
        .finally(() => {
          setSubmitting(false);
        });
    },
    [code, submitting, setSubmitting, infoPopup, setError, email]
  );

  return (
    <>
      {pageValid && email ? (
        <section>
          <div className="bp-container">
            <SEO title={t.title} description={t.metaDescription} robots="noindex" />
            <h1>{t.heading}</h1>
            <p className="bp-subheading">{t.description}</p>

            <Form fields={FIELDS} onSubmit={submitForm} displayGlobalError errorMessages={t.errors}>
              {({ setFieldValue, fields }) => (
                <>
                  <FormInput
                    id="password"
                    label={t.password}
                    type="password"
                    field={fields.password}
                    onChange={setFieldValue}
                    autoFocus
                  />

                  <Button type="submit" className="bp-standard bp-submit-button" busy={submitting}>
                    {t.submit}
                  </Button>
                </>
              )}
            </Form>
          </div>
        </section>
      ) : (
        <SpinnerOverlay />
      )}

      {error ? (
        <StatusModal
          error
          heading={error.heading}
          description={error.description}
          onClose={errorCloseCallback}
        />
      ) : null}
    </>
  );
};

export default AccountActionPage;
